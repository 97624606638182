.hero {
	background: map-get($theme-colors, brand-3);
	height: 80vh;
	min-height: map-get($grid-breakpoints, md);
	max-height: 75rem; //~1200px
	position: relative;

	@include media-breakpoint-down(xs){
		height: 100vh;
		min-height: #{map-get($grid-breakpoints, md) / 2};
	}

	&-content {
		position: relative;
		display: flex;
		align-items: flex-end;
		height: 100%;
		z-index: 2;

		padding-bottom: $sh-height-md;
		padding-left: $sh-height-lg;
		padding-right: #{$grid-gutter-width / 2};

		@include media-breakpoint-up(xxl){
			padding-left: #{$sh-height-lg + $sh-height-md};
			padding-right: $sh-height-md;
		}
		@include media-breakpoint-down(lg){
			padding-bottom: #{$sh-height-sm / 2};
			padding-left: $grid-gutter-width;
		}

		@include media-breakpoint-down(xs){
			padding-left: #{$grid-gutter-width / 2};
		}


	}

	&-body {
		margin-top: 0;
		//max-width: $container-max-width;
		max-width: map-get($grid-breakpoints, lg);

		@include media-breakpoint-between(lg,xl){
			width: 80%;
		}
	}

	&__title {
		display: block;
		line-height: 1.3;
		margin-top: 0;
		margin-bottom: .5em;

		span {
			background-color: $white;
			box-shadow: 1rem 0 0 0 $white, -1rem 0 0 0 $white;
			color: $black;
			display: inline;
			padding: 0 0 .2em;
			position: relative;
			transition: background .25s ease,
						box-shadow .25s ease,
						color .25s ease;

			@include media-breakpoint-down(sm){
				box-shadow: .5rem 0 0 0 $white, -.5rem 0 0 0 $white;
			}
		}

		&::before,
		&::after {
			content: '';
			display: table;
		}
	}

	&__buttons {
		margin-left: -1rem;
		margin-right: -1rem;

		@include media-breakpoint-down(sm){
			margin-left:  -.5rem;
			margin-right: -.5rem;
		}


		.btn.--outline:not([disabled]) {
			color: $white;

			&:hover,
			&:active,
			&:focus {
				color: $black;
			}
		}

	}


	//-- BACKGROUND --

	.bg {
		padding-left: $sh-height-md;
		padding-top: $sh-height-md;

		@include media-breakpoint-down(xs){
			padding-left: 0;
			padding-top: 0;
		}
		@include media-breakpoint-only(sm){
			padding-left: $sh-height-sm;
			padding-top: $sh-height-sm;
		}
		@include media-breakpoint-up(xxl){
			padding-top: $sh-height-lg;
			padding-left: $sh-height-lg;
		}

		//also called "steps"
		&::after,
		&::before {
			@include overlay(
				$ttb: false
			);
			background: map-get($theme-colors, brand-3);
			content: '';
			pointer-events: none;
			transition: height .25s ease;
			z-index: 3;
		}

		&::after {
			height: #{$sh-height-sm * 1.5};
			max-width: map-get($grid-breakpoints, md);
			width: 90%;

			@include media-breakpoint-between(md,xl){
				height: $sh-height-lg;
			}

			@include media-breakpoint-up(xxl){
				height: $sh-height-lg;
				max-width:  map-get($grid-breakpoints, lg);
			}
		}

		&::before {
			height: #{$sh-height-sm * 3};
			max-width: map-get($grid-breakpoints, sm);
			width: 80%;

			@include media-breakpoint-between(md,xl){
				height: #{$sh-height-lg * 2};
			}

			@include media-breakpoint-up(xxl){
				height: #{$sh-height-lg * 2.5};
				max-width: map-get($grid-breakpoints, md);
			}
		}
	}

	&.--alt {
		background: $secondary;

		.hero-content {
			margin: 0 auto;
			max-width: $container-max-width;

			@include media-breakpoint-between(sm,lg){
				padding-left: 3.75rem;
				padding-right: 3.75rem;
			}

			@include media-breakpoint-up(xl){
				padding-left: 0;
				padding-right: 0;
			}

			.tag {
				margin-left:  -1rem;
				margin-bottom: -.0625rem;

				@include media-breakpoint-down(sm){
					margin-left:  -.5rem;
				}
			}
		}

		.bg {
			left: auto;
			right: 0;

			@include media-breakpoint-up(xl){
				padding-left: 0;
			}

			.bg-i {
				margin-left: auto;

				@include media-breakpoint-up(xl){
					width: calc(50% + (#{map-get($grid-breakpoints, xl)} / 2));
				}
			}

			&::after,
			&::before {
				position: absolute;
				background: $white;
				bottom:0;
				height: 5rem;
				content: '';
				left:auto;
				z-index: 1000;
				width: calc(50% - (#{map-get($grid-breakpoints, xl)} / 2));
			}
			&::after {
				right: 0;
			}
			&::before {
				left: 0;
			}
		}
	}


	.js & {
		&.has-reveal {
			.tag,
			.hero__title,
			.hero__buttons {
				opacity: 0;
				transform: translateY(1rem);
				transition: transform .5s ease 0s,
							opacity .5s ease 0s;
			}
		}
		&.is-animated {
			.tag,
			.hero__title,
			.hero__buttons {
				opacity: 1;
				transform: translateY(0);
			}
			.hero__title {
				transition-delay: .2s;
			}
			.hero__buttons {
				transition-delay: .4s;
			}
		}

		&.is-animated:not(.--alt) {
			.bg::after,
			.bg::before {
				transform-origin: 0 0;
				transform: scaleX(0);
				animation: come-in 1s $easeInOutQuart forwards;
			}
		}
	}
}

@keyframes come-in {
	0% {
		transform: scaleX(0);
	}
	100% {
		transform: scaleX(1);
	}
}
@keyframes come-up {
	0% {
		transform: scaleY(0);
	}
	100% {
		transform: scaleY(1);
	}
}

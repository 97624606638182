@mixin customize($arg: theme) {
	$array: $themes;
	$prefix: theme;

	@if ($arg==theme) {
		$array: $themes;
		$prefix: theme;
	}

	@else {
		$array: $color-schemes;
		$prefix: color;
	}

	@each $theme,
	$map in $array {
		[data-#{$prefix}=#{$theme}] & {
			$array-map: () !global;

			@each $key,
			$submap in $map {
				$value: map-get(map-get($array, $theme), '#{$key}');
				$array-map: map-merge($array-map, ($key: $value)) !global;
			}

			@content;
			$array-map: null !global;
		}
	}
}

@function getValue($key) {
	@return map-get($array-map, $key);
}
.form {
	&-fieldset {
		margin-bottom: map-get($spacing, md);	
	}
	
	&-group {
		margin-bottom: $spacer;
	}
	
	&-label {
		display: block;
		font-size: $font-size-sm;
		font-weight: $font-weight-bold;
		margin-bottom: .5rem;
	}
	
	&-control {
		-webkit-appearance: textfield;
		-moz-appearance: textfield;
		appearance: textfield;
		
		background-clip: border-box;
		border: .1875rem solid $gray-200;
		border-radius: 0;
		font-size: $font-size-md;
		min-height: 3.125rem;
		
		&:hover {
			border-color: $gray-400;
		}
		
		&:focus {
			border-color: $input-focus-border-color;
		}
		
		@at-root {
			select#{&}{
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				cursor: pointer;
			}

			textarea#{&}{
				min-height: 150px;
			}
		}
		
		&.input-validation-error {
			background: lighten($danger, 45%);
			border-color: $danger;
			
			&:focus {
				box-shadow: 0 0 0 .2rem lighten($danger, 40%);
			}
		}
		
		&.datepickerfield {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke-width='2' fill='none' stroke='"+rgba($gray-700, .99)+"'%3E%3Crect x='5' y='12' width='2' height='1'%3E%3C/rect%3E%3Crect x='11' y='12' width='2' height='1'%3E%3C/rect%3E%3Crect x='5' y='17' width='2' height='1'%3E%3C/rect%3E%3Crect x='11' y='17' width='2' height='1'%3E%3C/rect%3E%3Crect x='17' y='12' width='2' height='1'%3E%3C/rect%3E%3Crect x='5' y='12' width='2' height='1' stroke='none'%3E%3C/rect%3E%3Crect x='11' y='12' width='2' height='1' stroke='none'%3E%3C/rect%3E%3Crect x='5' y='17' width='2' height='1' stroke='none'%3E%3C/rect%3E%3Crect x='11' y='17' width='2' height='1' stroke='none'%3E%3C/rect%3E%3Crect x='17' y='12' width='2' height='1' stroke='none'%3E%3C/rect%3E%3Crect x='1' y='4' width='22' height='18'%3E%3C/rect%3E%3Cline x1='17' y1='4' x2='17' y2='1'%3E%3C/line%3E%3Cline x1='7' y1='4' x2='7' y2='1'%3E%3C/line%3E%3Cline x1='1' y1='8' x2='23' y2='8'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-position: .625rem .625rem;
			padding-left: 3rem;
		}
	}
	
	&-select-wrapper {
		position: relative;
		
		&::after {
			content: '';
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			right: $spacer;
			pointer-events: none;
			width: $spacer;
			height: .125rem;
			background: $gray-300;
			box-shadow: 0 .3rem 0 $gray-300,
						0 -.3rem 0 $gray-300;
			transition: all .25s ease;
		}
		
		&:hover::after {
			background: $gray-500;
			box-shadow: 0 .3rem 0 $gray-500,
						0 -.3rem 0 $gray-500;
		}
	}
	
	&-help {
		display: block;
		color: $gray-500;
		cursor: help;
		font-size: $font-size-sm;
		padding: .5em 0;
		transition: color .15s ease;
				
		&:hover {
			color: $black;	
		}
	}
	
	&-indicator {
		color: $danger;
	}
	
	&-buttons {
		
		&.--center {
			text-align: center;
		}
	}
	
	.field-validation-error {
		@include font-size($font-size-sm);
		display: block;
		color: $danger;
		padding: .5em 0;
		
		> span,
		&::before {
			display: inline-block;
			vertical-align: middle;
		}
		
		&::before {
			width: $spacer;
			height: $spacer;
			background: $danger;
			border-radius: 50%;
			content: '!';
			text-align: center;
			color: $white;
			margin-right: .5em;
			font-weight: $font-weight-bold;
			font-size: $font-size-xs;
			line-height: 1.2;
		}
	}
	
	// FORM CHECKBOXES
	// .form-check
	&-check {
		padding-top: $spacer;
		padding-bottom: $spacer;
		padding-left: 0;
		margin:0;
		
		.form-label + & {
			padding-top: 0;
		}

		&__label {
			display: flex;
			align-items: center;
			
			cursor: pointer;
			position: relative;
			margin: 0;
			padding: .25em;

			&:hover {
				.form-check__input + .form-check__tick {
					border-color: $gray-400;
				}
				.form-check__input:checked + .form-check__tick {
					border-color: darken($primary, 7.5%);
					background: darken($primary, 7.5%);
				}
			}
			
			.--row & {
				margin-right:  map-get($spacing, sm);
					
				@include media-breakpoint-down(sm){
					margin-right: 0;
				}
			}
		}

		&__input {
			position: relative;
			float: left;
			height: 0;
			width: 0;
			left: -9999px;

			&:focus {
				& + .form-check__tick {
					border-color: lighten($primary, 25%);
					box-shadow: 0 0 0 .2rem rgba(darken($primary,5%), .25);
				}
			}
			
			&.input-validation-error {
				& + .form-check__tick {
					background: lighten($danger, 45%);
					border-color: $danger;
				}
				&:focus + .form-check__tick {
					box-shadow: 0 0 0 .2rem lighten($danger, 40%);
				}
			}
		}

		&__text {
			flex: 2;
			padding-left: map-get($spacing, sm);
		}

		&__tick {
			align-self: flex-start;
			position: relative;
			display: block;
			min-width: #{$spacer * 2};
			height: #{$spacer * 2};
			vertical-align: middle;
			overflow: hidden;
			background: $white;
			border: .1875rem solid $gray-200;
			border-radius: 0;
			margin-top: .25em;
			transition: all .5s ease;
			
			&:before,
			&:after {
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				height: 2px;
				background: $black;
				transform-origin: 0 0;
			}

			&:before {
				width: #{$spacer / 2};
				transform: rotate(45deg) translate(-5px, 5px) scaleX(0);
				transition: transform .25s ease .25s;
			}

			&:after {
				width: #{$spacer};
				transform: rotate(-45deg) translate(-7px, 2px) scaleX(0);
				transition: transform .25s ease 0s;
			}

			@at-root .--radio #{&} {
				border-radius: 50%;

				&::after,
				&::before {
					transform-origin: 50% 50%;
				}

				&::after {
					content: none;
				}

				&::before {
					top: 50%;
					width: 1rem;
					height: 1rem;
					transform: translate(-50%,-50%) scale(0);
					border-radius: 50%;
				}

			}
		}

		&__input:checked + &__tick {
			border-color: $primary;
			background: $primary;
			//box-shadow: 0 1px 8px rgba($primary, .5);
			box-shadow: 0 0 0 .2rem rgba(darken($primary,5%), .25);

			&:before {
				transform: rotate(45deg) translate(-5px, 5px) scaleX(1);
				transition: transform .25s ease 0s;
			}
			&:after {
				transform: rotate(-45deg) translate(-7px, 2px) scaleX(1);
				transition: transform .25s ease .25s;
			}

			@at-root .--radio #{&} {
				&:before {
					transform: translate(-50%,-50%) scale(1);
				}
			}
		}

		&.--row {
			display: flex;
			flex-flow: row wrap;
			
			@include media-breakpoint-down(sm){
				flex-flow: column wrap;
			}
		}
	}
}

	
	
	
	
	
	
	
	
	
	
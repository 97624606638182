/**
 * @license
 * MyFonts Webfont Build ID 3846003, 2019-12-10T04:22:48-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FreightSansProBook-Italic by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/book-italic/
 * Copyright: Copyright &#x00A9; 2004-2009, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProBlack-Italic by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/black-italic/
 * Copyright: Copyright &#x00A9; 2004-2009, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProBold-Italic by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/bold-italic/
 * Copyright: Copyright &#x00A9; 2004-2009, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProSemibold-Italic by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/semibold-italic/
 * Copyright: Copyright &#x00A9; 2004-2009, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProMedium-Italic by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/medium-italic/
 * Copyright: Copyright &#x00A9; 2004-2009, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProLight-Italic by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/light-italic/
 * Copyright: Copyright &#x00A9; 2004-2009, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProSemibold-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/semibold/
 * Copyright: Copyright &#x00A9; 2004-2009, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProLight-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/light/
 * Copyright: Copyright &#x00A9; 2004-2009, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProBlack-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/black/
 * Copyright: Copyright &#x00A9; 2004-2009, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProBold-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/bold/
 * Copyright: Copyright &#x00A9; 2004-2009, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProMedium-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/medium/
 * Copyright: Copyright &#x00A9; 2004-2009, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * Webfont: FreightSansProBook-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-sans-pro/book/
 * Copyright: Copyright &#x00A9; 2004-2010, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 * 
 * 
 * Licensed pageviews: 100,000
 * 
 * © 2019 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3aaf73");


//-----  NORMAL

//@font-face {
//	font-family: 'FreightSansPro';
//	src: url('/content/fonts/FreightSans/3AAF73_8_0.woff2') format('woff2'),
//		url('/content/fonts/FreightSans/3AAF73_8_0.woff') format('woff');
//	font-display: swap;
//	font-weight: 800;
//	font-style: normal;
//}

//@font-face {
//	font-family: 'FreightSansPro';
//	src: url('/content/fonts/FreightSans/3AAF73_9_0.woff2') format('woff2'),
//		url('/content/fonts/FreightSans/3AAF73_9_0.woff') format('woff');
//	font-weight: 700;
//	font-style: normal;
//	font-display: swap;
//}

@font-face {
	font-family: 'FreightSansPro';
	src: url('/content/fonts/FreightSans/3AAF73_6_0.woff2') format('woff2'),
		url('/content/fonts/FreightSans/3AAF73_6_0.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

//@font-face {
//	font-family: 'FreightSansPro';
//	src: url('/content/fonts/FreightSans/3AAF73_A_0.woff2') format('woff2'),
//		url('/content/fonts/FreightSans/3AAF73_A_0.woff') format('woff');
//	font-weight: 500;
//	font-style: normal;
//	font-display: swap;
//}

@font-face {
	font-family: 'FreightSansPro';
	src: url('/content/fonts/FreightSans/3AAF73_B_0.woff2') format('woff2'),
		url('/content/fonts/FreightSans/3AAF73_B_0.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

//@font-face {
//	font-family: 'FreightSansPro';
//	src: url('/content/fonts/FreightSans/3AAF73_7_0.woff2') format('woff2'),
//		url('/content/fonts/FreightSans/3AAF73_7_0.woff') format('woff');
//	font-weight: 300;
//	font-style: normal;
//	font-display: swap;
//}

//-----  ITALIC

//@font-face {
//	font-family: 'FreightSansPro';
//	src: url('/content/fonts/FreightSans/3AAF73_1_0.woff2') format('woff2'),
//		url('/content/fonts/FreightSans/3AAF73_1_0.woff') format('woff');
//	font-weight: 800;
//	font-style: italic;
//	font-display: swap;
//}

//@font-face {
//	font-family: 'FreightSansPro';
//	src: url('/content/fonts/FreightSans/3AAF73_2_0.woff2') format('woff2'),
//		url('/content/fonts/FreightSans/3AAF73_2_0.woff') format('woff');
//	font-weight: 700;
//	font-style: italic;
//	font-display: swap;
//}

@font-face {
	font-family: 'FreightSansPro';
	src: url('/content/fonts/FreightSans/3AAF73_3_0.woff2') format('woff2'),
		url('/content/fonts/FreightSans/3AAF73_3_0.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

//@font-face {
//	font-family: 'FreightSansPro';
//	src: url('/content/fonts/FreightSans/3AAF73_4_0.woff2') format('woff2'),
//		url('/content/fonts/FreightSans/3AAF73_4_0.woff') format('woff');
//	font-weight: 500;
//	font-style: italic;
//	font-display: swap;
//}

@font-face {
	font-family: 'FreightSansPro';
	src: url('/content/fonts/FreightSans/3AAF73_0_0.woff2') format('woff2'),
		url('/content/fonts/FreightSans/3AAF73_0_0.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

//@font-face {
//	font-family: 'FreightSansPro';
//	src: url('/content/fonts/FreightSans/3AAF73_5_0.woff2') format('woff2'),
//		url('/content/fonts/FreightSans/3AAF73_5_0.woff') format('woff');
//	font-weight: 300;
//	font-style: italic;
//	font-display: swap;
//}
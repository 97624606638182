.sh {
	background: $sh-bg;
	
	display: flex;
	align-items: center;
	justify-content: space-between;
	
	height: $sh-height-sm;
	position: $sh-position;
	width: 100%;
	transition: $sh-transition;
	z-index: $sh-zIndex;
	
	//padding-left: $spacer;
	padding-right: $spacer;
	
	@include media-breakpoint-between(md,xl){
		padding-left: map-get($spacing, sm);
		padding-right: map-get($spacing, sm);
		height: $sh-height-md;
	}
	
	@include media-breakpoint-up(xxl){
		padding-left: map-get($spacing, md);
		padding-right: map-get($spacing, md);
		height: $sh-height-lg;
	}

	
	&__left {
		display: flex;
		align-items: center;
		
		&,
		.nav__menu,
		.nav__menu-item, 
		.nav__menu-item > a {
			height: 100%;
		}
		
		.nav {			
			&__toggle {
				width: $sh-nav-size;
				height: $sh-nav-size;
			}
			
			&__menu {
				@include list-flex;
				//@include font-size($font-size-base);
				font-weight: $font-weight-bold;
				
				@include media-breakpoint-down(md){
					display: none;
				}
				
				&-item {					
					@include media-breakpoint-up(xxl){
						margin-left: $spacer;
						margin-right: $spacer;
					}
					
					&:first-child {
						margin-left: $spacer;
						
						@include media-breakpoint-up(xxl){
							margin-left: #{$spacer * 2};
						}
					}
				}
				
				a {
					color: $white;
					display: table;
					padding: $spacer;
					position: relative;
					
					> span {
						display: table-cell;
						vertical-align: middle;
						transition: opacity $transTime $transEase;
					}
					
					//.--inverted & {
					//	color: $sh-link-alt;
					//}
					
					&::before {
						@include overlay($h: .25rem);
						background: $sh-link-active;
						content: '';
						transform: scaleY(0);
						transform-origin: 0 0;
						transition: transform $transTime $transEase;
					}
					
					&:hover,
					&:focus {
						> span {
							opacity: .5;
						}
					}
					
					//&:hover,
					&:active,
					&:focus {
						&::before {
							transform: scaleY(1);
						}	
					}
				}
				
				.--active > a::before {
					transform: scaleY(1);
				}
			}
		}
		
	}
	
	&__right {
		
	}
	
	&.--scrolled {
		@include media-breakpoint-up(md){
			height: $sh-height-md;
		}
	}
	
	&.--scrolled {
		background: $sh-bg-alt;
	}
	&.--inverted {
		background: $sh-bg-alt;
	}
	
}


.brand {
	&__logo {
		.sh & {
			width: 200px;
			fill: $white;
			transition: width .25s ease;
			
			@include media-breakpoint-up(xxl){
				width: 300px;
			}
			
			@include media-breakpoint-down(sm){
				width: 150px;
			}
		}
		//.sh.--inverted & {
		//	fill: $black;
		//}
		.sh.--scrolled & {
			@include media-breakpoint-up(md){
				width: 200px;
			}
		}
	}
}
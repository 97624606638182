.tag {
	position: relative;
	display: table;
	padding: .375em .75em;
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	font-size: $font-size-sm;
	
	background: $primary;
	color: $black;
	letter-spacing: .03em;
	
	@include customize(theme) {
		background: getValue(bgColor);
		color: $white;
	}
	
	@include media-breakpoint-down(md){
		font-size: $font-size-xs;
	}
	
	&::after {
		@include overlay(
			$w: 1rem,
			$h: 50%,
			$x: 100%,
			$ttb: false
		);
		background: $primary;
		content: '';
		pointer-events: none;
		transition: all .25s ease;
		
		@include customize(theme) {
			background: getValue(bgColor);
		}
	}
	
	@at-root a#{&} {
		&:hover,
		&:active,
		&:focus {
			color: $black;
			background: darken($primary, 10%);
			
			&::after {
				background: darken($primary, 10%);
			}
		}
	}
}
//## Create section-paddings (Y-AXIS)
@mixin py-spacing (
        $xs : map-get($spacing, md),
        $sm : map-get($spacing, md),
        $md : map-get($spacing, md),
        $lg : map-get($spacing, lg),
        $xl : map-get($spacing, lg),
		$xxl : map-get($spacing, xl),
		$xxxl : map-get($spacing, xl),
		$nav : false,
		$low : false,
		$only : false
    ){

	$sh-height-mobile: 0 !default;
	$sh-height-tablet: 0 !default;
	$sh-height-desktop: 0 !default;
	$split: 1 !default;

	@if $nav == true {
		$sh-height-mobile: $sh-height-sm;
		$sh-height-tablet: $sh-height-md;
		$sh-height-desktop: $sh-height-lg;
	}
	@if $low == true {
		$split: 2;
	}

    @include media-breakpoint-down(xs){
		@if ($only == "top") or ($only == false){
			padding-top: (($xs / $split) + $sh-height-mobile);
		}
		@if ($only == "bottom") or ($only == false){
			padding-bottom: ($xs / $split);
		}
	}
	@include media-breakpoint-only(sm){
		@if ($only == "top") or ($only == false){
			padding-top: (($sm / $split) + $sh-height-mobile);
		}
		@if ($only == "bottom") or ($only == false){
			padding-bottom: ($sm / $split);
		}
	}
	@include media-breakpoint-only(md){
		@if ($only == "top") or ($only == false){
			padding-top: (($md / $split) + $sh-height-tablet);
		}
		@if ($only == "bottom") or ($only == false){
			padding-bottom: ($md / $split);
		}
	}
	@include media-breakpoint-only(lg){
		@if ($only == "top") or ($only == false){
			padding-top: (($lg / $split) + $sh-height-tablet);
		}
		@if ($only == "bottom") or ($only == false){
			padding-bottom: ($lg / $split);
		}
	}
	@include media-breakpoint-only(xl){
		@if ($only == "top") or ($only == false){
			padding-top: (($xl / $split) + $sh-height-tablet);
		}
		@if ($only == "bottom") or ($only == false){
			padding-bottom: ($xl / $split);
		}
	}
	@include media-breakpoint-up(xxl){
		@if ($only == "top") or ($only == false){
			padding-top: (($xxl / $split) + $sh-height-desktop);
		}
		@if ($only == "bottom") or ($only == false){
			padding-bottom: ($xxl / $split);
		}
	}
}


//## Create section-margin (Y-AXIS)
@mixin my-spacing (
        $xs : map-get($spacing, md),
        $sm : map-get($spacing, md),
        $md : map-get($spacing, md),
        $lg : map-get($spacing, lg),
        $xl : map-get($spacing, lg),
		$xxl : map-get($spacing, xl),
		$xxxl : map-get($spacing, xl),
		$nav : false,
		$low : false,
		$only : false
    ){

	$sh-height-mobile: 0 !default;
	$sh-height-tablet: 0 !default;
	$sh-height-desktop: 0 !default;
	$split: 1 !default;

	@if $nav == true {
		$sh-height-mobile: $sh-height-sm;
		$sh-height-tablet: $sh-height-md;
		$sh-height-desktop: $sh-height-lg;
	}
	@if $low == true {
		$split: 2;
	}

    @include media-breakpoint-down(xs){
		@if ($only == "top") or ($only == false){
			margin-top: (($xs / $split) + $sh-height-mobile);
		}
		@if ($only == "bottom") or ($only == false){
			margin-bottom: ($xs / $split);
		}
	}
	@include media-breakpoint-only(sm){
		@if ($only == "top") or ($only == false){
			margin-top: (($sm / $split) + $sh-height-mobile);
		}
		@if ($only == "bottom") or ($only == false){
			margin-bottom: ($sm / $split);
		}
	}
	@include media-breakpoint-only(md){
		@if ($only == "top") or ($only == false){
			margin-top: (($md / $split) + $sh-height-tablet);
		}
		@if ($only == "bottom") or ($only == false){
			margin-bottom: ($md / $split);
		}
	}
	@include media-breakpoint-only(lg){
		@if ($only == "top") or ($only == false){
			margin-top: (($lg / $split) + $sh-height-tablet);
		}
		@if ($only == "bottom") or ($only == false){
			margin-bottom: ($lg / $split);
		}
	}
	@include media-breakpoint-only(xl){
		@if ($only == "top") or ($only == false){
			margin-top: (($xl / $split) + $sh-height-tablet);
		}
		@if ($only == "bottom") or ($only == false){
			margin-bottom: ($xl / $split);
		}
	}
	@include media-breakpoint-up(xxl){
		@if ($only == "top") or ($only == false){
			margin-top: (($xxl / $split) + $sh-height-desktop);
		}
		@if ($only == "bottom") or ($only == false){
			margin-bottom: ($xxl / $split);
		}
	}
}

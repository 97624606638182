//// --------------------------------------------------------------------
//// BOOTSTRAP 4 - OVERIDES
//// ## "!Default" aren't bootstrap defaults but custom variables.
//// --------------------------------------------------------------------

//== Color system
$black:									#111;
$gray-100: 								#f5f5f5;
$gray-200: 								#e9ecef;
$gray-300: 								#dee2e6;
$gray-400: 								#ced4da;
$gray-500: 								#adb5bd;
$gray-600: 								#6c757d;
$gray-700: 								#495057;
$gray-800: 								#343a40;
$gray-900: 								#222222;
$white:									#fff;

$primary:      					  		#edd656;
$secondary:    					  		#bab2a7;
$third:									#41453f !default;
$fourth:								#e9a25b !default;

$theme-colors: (
	brand-1:							$primary,
	brand-2:							$secondary,
	brand-3:							$third,
	brand-4:							$fourth,
	brand-5:							#106443,
	brand-6:							#82c39e,
	brand-7: 							#0a8acb,
	brand-8: 							#a4c7d7
);

$social-brand: (
	facebook: 							#3b5998,
	instagram: 							#000,
	linkedin:							#000,
	rss: 								#000,
	twitter: 							#000,
	youtube: 							#000
) !default;

//$primary-gradient:						linear-gradient(90deg, rgba($primary, 1) 0%, rgba($secondary, 1) 100%);

$body-bg:                   			$white;
$body-color:							$gray-800;

//== Fonts
$font-size-base:                		1.25rem;
$font-size-lg:                			($font-size-base * 1.2);
$font-size-md:                			($font-size-base * .90) !default;
$font-size-sm:                			($font-size-base * .8);
$font-size-xs:                			($font-size-base * .7) !default;

$h1-font-size:                			$font-size-base * 4.0;	//~80px
$h2-font-size:                			$font-size-base * 2.4;	//~48px
$h3-font-size:                			$font-size-base * 1.6;	//~32px
$h4-font-size:                			$font-size-base * 1.2;	//~24px
$h5-font-size:                			$font-size-base * 1.0;	//~2px
$h6-font-size:                			$font-size-base * 0.9;	//~18px

$lead-font-size:              			$font-size-base * 1.3; //~24
$lead-font-weight:            			400;

$line-height-base:						1.5;
$line-height-lg:              			1.7;
$line-height-sm:              			1.3;


$font-family-sans-serif:				'FreightSansPro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-serif:						Georgia, "Times New Roman", Times, serif !default;
$font-family-display:					$font-family-sans-serif !default;
$font-family-display-sm:				$font-family-sans-serif !default;
$font-family-base:						$font-family-sans-serif;

$font-weight-bolder:            		800 !default;
$font-weight-bold:            			600;
$font-weight-base:						400;
$font-weight-thin:            			300 !default;

$headings-color:						$black;
$headings-font-family:					$font-family-display;
$headings-font-weight:					$font-weight-bold;
$headings-line-height:                  1.3;

//== Grid columns
$grid-columns:                		    12;
$grid-column:							percentage(1/$grid-columns) !default;
$grid-gutter-width:           			2.5rem;

//== Grid containers
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
		xs: 							0,
		sm: 							576px,
		md: 							768px,
		lg: 							992px,
		xl: 							1320px,
		xxl: 							1560px,
		xxxl:							1920px
);

// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
		sm: 							540px,
		md: 							720px,
		lg: 							960px,
		xl: 							1260px,
		xxl: 							1520px,
		xxxl:							1880px
);

$container-max-width:					map-get($grid-breakpoints, xl) !default;

//== Buttons
$btn-padding-y:							.875em;
$btn-padding-x:							2em;
$btn-border-width:						.1875rem;
//$btn-border-width:					0;
$btn-border-radius:						0;
$btn-font-size:							$font-size-sm;
$btn-font-weight:						$font-weight-bold;
$btn-line-height:						$line-height-sm;


$btn-box-shadow:                        0; //inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
$btn-focus-color:                       0; //-webkit-focus-ring-color auto 5px
$btn-focus-width:                       0;
$btn-focus-box-shadow:                	none; //auto $btn-focus-width $btn-focus-color;
//$btn-active-box-shadow:      			none;


//== Links
$link-color:                            $primary;
$link-decoration:                       none;
$link-hover-color:                      darken($link-color, 10%);
$link-hover-decoration:					none;



//// --------------------------------------------------------------------
//// GENERIC VARIABLES
//// --------------------------------------------------------------------

//== Spacing

$spacer: 								1rem !default;

$spacing: (
	xs: 								.625rem,	//10px
	sm: 								1.25rem,	//20px
	md: 								2.5rem,		//40px
	lg: 								3.75rem,	//60px
	xl: 								5rem,		//80px
	xxl: 								7.5rem,		//120px
	xxxl: 								8.75rem		//140px
) !default;

//== Page offset padding
//## Calculates where to readjust horizontal padding (eg. site-header)
//## The algorithm resets PX from breakpoints and recalculate it to REM.
//Example: (1560 / ((1560*0+1)/16) + (120*2) ~ 112.5rem = 1800px
$page-offset:                           #{(map-get($grid-breakpoints, xxl)/(map-get($grid-breakpoints, xxl)*0+1)/16) + map-get($spacing, xxxl)} !default;

$page-offset-base: 						map-get($spacing, md) !default; //40
$page-offset-lg: 						$page-offset-base !default; //40
$page-offset-sm: 						$page-offset-base !default; //40
$page-offset-xs:						($page-offset-base / 2) !default; //20


//== Icons / Glyphs
$icon-size-base:                       	1.5rem !default;
$icon-size-xl:                       	4rem !default;
$icon-size-lg:                       	3rem !default;
$icon-size-md:                       	2rem !default;
$icon-size-sm:                         	1rem !default;

$icon-color:                            $primary !default;
$icon-color-hover:						darken($primary, 15%) !default;
$icon-current-color:                    red !default;
$icon-current-color-hover:				blue !default;
$icon-stroke-width:                     2px !default;
$icon-transition:                       all .25s ease !default;


//== Easings / Animations
$easeInOutQuart:						cubic-bezier(0.77, 0, 0.175, 1) !default;
$easeInOutBounce:						cubic-bezier(0.650, -0.090, 0.335, 1.135) !default;
$easeInOutQuart2:						cubic-bezier(0.665, 0.110, 0.170, 0.870) !default;


//== Transitions
$transTime:								.25s;
$transEase:								ease;
$transition:							all $transTime $transEase;

$themes: (
	yellow: (
		bgColor: map-get($theme-colors, brand-1),
		bgDark: darken(map-get($theme-colors, brand-1), 10%),
		bgLight: lighten(map-get($theme-colors, brand-1), 10%),
		textColor: map-get($theme-colors, brand-1),
		textDark: darken(map-get($theme-colors, brand-1), 10%),
		textLight: lighten(map-get($theme-colors, brand-1), 10%),
		iconColor: #000,
	),
	green: (
		bgColor: map-get($theme-colors, brand-6),
		bgDark: darken(map-get($theme-colors, brand-6), 10%),
		bgLight: lighten(map-get($theme-colors, brand-6), 10%),
		textColor: map-get($theme-colors, brand-6),
		textDark: darken(map-get($theme-colors, brand-6), 10%),
		textLight: lighten(map-get($theme-colors, brand-6), 10%),
		iconColor: #000,
	),
	blue: (
		bgColor: map-get($theme-colors, brand-7),
		bgDark: darken(map-get($theme-colors, brand-7), 10%),
		bgLight: lighten(map-get($theme-colors, brand-7), 10%),
		textColor: map-get($theme-colors, brand-7),
		textDark: darken(map-get($theme-colors, brand-7), 10%),
		textLight: lighten(map-get($theme-colors, brand-7), 10%),
		iconColor: #000,
	),
	orange: (
		bgColor: map-get($theme-colors, brand-4),
		bgDark: darken(map-get($theme-colors, brand-4), 10%),
		bgLight: lighten(map-get($theme-colors, brand-4), 10%),
		textColor: map-get($theme-colors, brand-4),
		textDark: darken(map-get($theme-colors, brand-4), 10%),
		textLight: lighten(map-get($theme-colors, brand-4), 10%),
		iconColor: #000,
	),
);

$color-schemes: (
	scheme-default: (
		bgColor: #00b4ff,
	),
	scheme-cs1: (
		bgColor: #e68a00,
	)
);

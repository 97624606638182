body {
	&,
	&.mce-content-body {
		@include font-size;
		font-weight: $font-weight-base;
		line-height: $line-height-base;
	}
}


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	position: relative;
	margin-bottom: .5em;
	letter-spacing: -.015em;
	
	* + & {
		margin-top: 4rem;
		
		@include media-breakpoint-down(sm){
			margin-top: 2rem;
		}
	}
}

h1, .h1 {
	@include font-size($h1-font-size, 40, 50, 65, 70, 80);
}
h2, .h2 {
	@include font-size($h2-font-size, 47, 60, 70, 70);
}
h3, .h3 {
	@include font-size($h3-font-size, 60, 70, 70);
}
h4, .h4 {
	@include font-size($h4-font-size, 70, 80, 80);
	color: $secondary;
	font-weight: $font-weight-base;
}
h5, .h5 {
	@include font-size($h5-font-size);
}
h6, .h6 {
	@include font-size($h6-font-size);
}

.title {
	&.--line {
		&::after {
			border-bottom: #{$spacer / 4} solid $primary;
			content: '';
			display: block;
			margin-top: .5em;
			margin-bottom: .5em;
			position: relative;
			width: #{map-get($spacing, lg)};
			
			@include customize(theme) {
				border-color: getValue(bgColor);
			}
		}
	}
}

p {
	margin-bottom: 1.5em;
	
	&.lead {
		@include font-size($lead-font-size, 70, 85, 85, 90, 100);
		color: $gray-600;
		line-height: $line-height-base;
		margin-bottom: 2em;
	}
	
	&:last-child {
		margin-bottom:0;
	}
}

.quote,
blockquote {
	position: relative;
	display: block;
	//overflow: hidden;
	margin-bottom: 2rem;
	padding: map-get($spacing, sm) map-get($spacing, sm) map-get($spacing, lg);
	opacity: .8;
	transition: opacity .25s ease;
	
	@include media-breakpoint-up(xl){
		max-width: 80%;
		padding-top: map-get($spacing, md);
		padding-right: map-get($spacing, md);
		padding-bottom: map-get($spacing, md);
		padding-left: map-get($spacing, lg);
	}
	
	@include media-breakpoint-down(sm){
		padding: map-get($spacing, sm) 0;
		opacity: .7;
	}
	
	&:hover {
		opacity: 1;
	}
	
	&:last-child {
		margin-bottom: 0;
	}
	
	&::before {
		position: relative;
		content: open-quote;
		font-family: $headings-font-family;
		font-size: 10rem;
		position: absolute;
		top:-.15em;
		left:0;
		line-height:1.2;
		color: $third;
		
		@include media-breakpoint-up(xl){
			top:0;
		}
		@include media-breakpoint-down(lg){
			z-index: -1;
			transform: translateX(-50%);
		}
		
		@include media-breakpoint-down(sm){
			color: $gray-300;
		}
	}
}

small,
.small,
.small-text {
	@include font-size($font-size-sm);
	color: $gray-600;
}
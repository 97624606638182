.svg {
    &-collection {
        display: none;
    }
	
	&-icon {
		&,
		&.--outline {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			width: $icon-size-base;
			height: $icon-size-base;
			transition: $icon-transition;
		}
		
		//default/glyph
		& {
			fill: $icon-color;
			
			*:hover > &,
			*:active > &,
			*:focus > & {
				fill: darken($third, 15%);
			}
		}

		//outlined icons
		&.--outline {
			fill:none;
			stroke: $icon-color;
			stroke-width: $icon-stroke-width;
			
			*:hover > &,
			*:active > &,
			*:focus > & {
				fill:none;
				stroke: darken($third, 15%);
			}
		}
	}
}

//## Reseting UL/OL Lists to unstyled.

//reset list (default)
@mixin list-reset {
    margin:0;
    padding:0;
    list-style: none;
}

//reset list (inline)
@mixin list-inline {
	@include list-reset;
	
	> li {
		display: inline;
	}
}

//reset list (inline-block)
@mixin list-inline-block {
	@include list-reset;
	
	> li {
		display: inline-block;
	}
}

//reset list (block)
@mixin list-block {
	@include list-reset;
	
	> li {
		display: block;
	}
}

//reset list (flexbox)
@mixin list-flex (
	$dir		:	row,
	$align		: 	flex-start,
	$justify	: 	flex-start,
	$wrap		:	wrap
) {
	@include list-reset;
	display: flex;
	align-items: $align;
	justify-content: $justify;
	flex-flow: $dir $wrap;
}
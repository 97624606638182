.sf {
	@include py-spacing($low: true);
	background: map-get($theme-colors, brand-3);
	color: $white;
	position: relative;
	
	&-top {
		@include py-spacing(
			$xl : map-get($spacing, lg),
			$xxl : map-get($spacing, xl),
			$only: top
		);
	}
	
	&-bottom {
		@include py-spacing(
			$xl : map-get($spacing, lg),
			$xxl : map-get($spacing, xl),
			$only: top
		);
	}
	
	.c-col {
		@include media-breakpoint-up(lg){
			@include make-col(3);
		}
		@include media-breakpoint-between(sm,md){
			@include make-col(4);
			
			&:last-child {
				@include make-col(12);
				text-align: center;
				margin-bottom: 0;
			}
		}
		@include media-breakpoint-down(xs){
			text-align: center;
			
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	.title {
		@include font-size($h4-font-size);
		color: $white;
	}
	
	a {
		font-weight: $font-weight-bold;
	}
	
	
	&__list {
		@include list-block;
				
		a {
			padding: .2em 0;
		}
	}
	
	.copy {
		font-size: $font-size-xs;
		text-align: center;
		color: rgba($white, .2);
	}
	
	.social {
		@include media-breakpoint-down(md){
			justify-content: center;
		}
	}
	
	&::before,
	&::after {
		@include overlay;
		content: '';
		width: calc(50% - #{map-get($grid-breakpoints, xl) / 2});
		background-repeat: no-repeat;
		background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 800' fill='"+ rgba($white, .05) +"'%3E%3Cpolygon points='800 71.86 728.14 0 699.86 0 800 100.14 800 71.86'/%3E%3Cpolygon points='800 171.86 628.14 0 599.86 0 800 200.14 800 171.86'/%3E%3Cpolygon points='800 271.86 528.14 0 499.86 0 800 300.14 800 271.86'/%3E%3Cpolygon points='800 371.86 428.14 0 399.86 0 800 400.14 800 371.86'/%3E%3Cpolygon points='800 471.86 328.14 0 299.86 0 800 500.14 800 471.86'/%3E%3Cpolygon points='800 571.86 228.14 0 199.86 0 800 600.14 800 571.86'/%3E%3Cpolygon points='800 671.86 128.14 0 99.86 0 800 700.14 800 671.86'/%3E%3Cpolygon points='800 800 800 771.86 28.14 0 0 0 0 0.14 799.86 800 800 800'/%3E%3C/svg%3E");
		background-size: 50rem 50rem;
	}
	&::before {
		transform: scale(-1);
	}
	&::after {
		left: auto;
		right: 0;
	}
}
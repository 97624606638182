.social-toolbar {
	text-align: center;	

	&__title:not(.sr-only) {
		display: block;
		font-size: $font-size-xs;
		font-family: $font-family-base;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		letter-spacing: .1em;
		color: $secondary;
		margin-bottom: 1.5rem;
	}
	
	.social {
		margin:0;
		
		> li {
			margin: .25rem;
			
			@include media-breakpoint-up(xl){
				margin: .75rem;
			}
		}
		
		&__icon {
			fill: $primary;
			
			@include media-breakpoint-up(xl){
				width: 32px;
				height: 32px;
			}
		}
		
		&__link {
			//border-color: $gray-500;
			&::after {
				content: none !important;
			}
			
			&:focus,
			&:hover,
			&:active {
				//border-color: $primary;
				
				.social__icon {
					fill: $secondary;
				}
			}
		}
	}
 }

.social {
	@include list-flex(
		$align: center
	);
	
	&__icon {
		display: block;
		width: 20px;
		height: 20px;
		fill: $primary;
		stroke: none;
		transition: fill .25s ease;

		@include media-breakpoint-up(lg) {
			width: 24px;
			height: 24px;
		}
		
		.--lg & {
			position: relative;
			width: 24px;
			height: 24px;
			margin:-2px;
			
			@include media-breakpoint-up(lg) {
				width: 32px;
				height: 32px;
				margin: -4px;
			}
		}
	}

	&__link {
		display: block;
		background: none;
		border-radius: 50%;
		padding: $spacer;
		transition: all .25s ease;

		&:focus,
		&:hover,
		&:active {
			.social__icon {
				fill: darken($primary, 10%);
			}
		}
		
		&::after {
			content: none;
		}
	}
}
.s {
	@include py-spacing;
	position: relative;
	
	@include media-breakpoint-up(xl){
		padding-left:  #{$grid-gutter-width};
		padding-right: #{$grid-gutter-width};
	}
	
	@include media-breakpoint-between(sm,lg){
		padding-left:  #{$grid-gutter-width / 2};
		padding-right: #{$grid-gutter-width / 2};
	}
	
	&.--pattern {
		.c {
			z-index: 3;
		}
		
		&::after {
			@include overlay();
			background-clip: content-box;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' fill='"+ rgba($gray-100, .99) +"'%3E%3Cpolygon points='100 14.14 100 0 85.86 0 100 14.14'/%3E%3Cpolygon points='0 0 0 14.14 85.86 100 100 100 100 85.86 14.14 0 0 0'/%3E%3Cpolygon points='0 100 14.14 100 0 85.86 0 100'/%3E%3C/svg%3E");
			background-position: 50%;
			background-size: 6rem;
			content: '';
			
			@include media-breakpoint-up(xxl){
				padding-top: $spacer * 15;
				padding-bottom: $spacer * 12;
				padding-left: $sh-height-lg;
				padding-right: $sh-height-lg;
			}
			@include media-breakpoint-only(xl){
				padding-top: $spacer * 14;
				padding-bottom: $spacer * 12;
				padding-left: $spacer * 2;
				padding-right: $spacer * 2;
			}
			
			@include media-breakpoint-only(lg){
				padding-top: $spacer * 11;
				padding-bottom: $spacer * 10;
				padding-left: $spacer * 2;
				padding-right: $spacer * 2;
			}
			
			@include media-breakpoint-down(md){
				content: none;	
			}
		}
	}
		
	&.--negative {
		@include media-breakpoint-up(xl){
			.c {
				margin-top: -3.75rem;

				&::before {
					@include overlay;
					content: '';
					background: $white;
					transform: scale(1.05, 1.5);
					z-index: 1;
				}
			}
			.c-row {
				position: relative;
				z-index: 2;
			}
		}
	}
	
	&.--spotlight {
		.hero + & {
			@include media-breakpoint-up(xl){
				.c {
					margin-top: -3.75rem;

					&::before {
						@include overlay;
						content: '';
						background: $white;
						transform: scale(1.05, 1) translateY(-$sh-height-sm);
						z-index: 1;
					}
				}
				.c-row {
					position: relative;
					z-index: 2;
				}
			}
		}
	}
	
	&--key-figures {
		@include py-spacing($low: true, $only: bottom);
		padding-top: 0;
		
		@include media-breakpoint-down(sm){
			@include hide-scrollbar;
			width: 100%;
			overflow-x: auto;
		}

		.c-row {
			justify-content: center;

			@include media-breakpoint-down(sm){
				justify-content: flex-start;
				flex-wrap: nowrap;

				margin-left: -#{$grid-gutter-width / 4};
				margin-right: -#{$grid-gutter-width / 4};

				transform-origin:  0 0;
				transform: scale(.75);
			}
		}
		.c-col {
			@include make-col(2);
			min-width: 13.75rem;

			@include media-breakpoint-down(sm){
				min-width: 12rem;
				padding-left: #{$grid-gutter-width / 4};
				padding-right: #{$grid-gutter-width / 4};
			}
		}
	}
	
	&--triple-media {
		@include py-spacing($low: true);
		
		@include media-breakpoint-down(xs){	
			padding-top:0;
			padding-bottom: 0;
		}
		
		.c-col {
			margin-bottom: 0;
			
			@include media-breakpoint-down(xs){	
				&:nth-child(1n+2){
					display: none;
				}
			}
		}
		
		.fig {
			margin-bottom: 0;
			z-index: 2;
			
			&.--crop {
				height: 100%;
				
				.fig__picture {
					height: 100%;
					overflow: hidden;
				}

				.fig__object {
					max-width: none;
					height: 100%;
					width: auto;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
		
		[data-square="type-1"]{
			z-index: 2; //fixes animated glitch
		}
		
		[data-square="type-1"]::after,
		[data-square="type-2"]::after,
		[data-square="type-3"]::after {
			position: absolute;
			content: '';
			z-index: -1;
			background: $gray-100;
		}
		
		[data-square="type-1"]::after {
			max-width: 320px;
			max-height: 210px;
			width: 100%;
			height: 50%;
			left: -60px;
			top: 40px;
		}
		[data-square="type-2"]::after {
			max-width: 320px;
			max-height: 210px;
			width: 100%;
			height: 50%;
			left: -50%;
			bottom: -20px;
		}
		[data-square="type-3"]::after {
			max-width: 320px;
			max-height: 210px;
			width: 100%;
			height: 50%;
			right: -60px;
			bottom: 60px;
		}
	}
	
	&:first-child {
		.sm > & {
			@include py-spacing($nav: true);
		}
	}
}
// dependencies:
// v-base, h-overlay

.bg {
	@include overlay;
	overflow: hidden;
	z-index: 1;

	//item
	&-i {
		position: relative;
		width: 100%;
		height: 100%;
		//background: $white;
		//opacity: .5;

		//item-object
		&__o {
			@include overlay;
			background-position: 50%;
			background-size: cover;
			//mix-blend-mode: multiply;

			.--movie & {
				object-fit: cover;
			}
		}
	}

	//-- modifiers

	//fade background
	&.--fade::before {
		@include overlay;
		background: $black;
		content:'';
		z-index: 2;
		mix-blend-mode: multiply;
		
		opacity: 0;
		animation: bgFade 3s ease 1s forwards;
	}
}

@keyframes bgFade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: .33;
	}
}

@supports (-ms-ime-align: auto) {
	.bg {
		&-i {
			.--movie & {
				width: auto;
				max-width: none;
				padding-bottom: 62.5%;

				@include media-breakpoint-down(md){
					padding-bottom: 100%;
					height: 100%;
				}

				&__o {
					min-width: 100%;
					width: auto;
					height: auto;
				}
				
				&__o.lazyloaded {
					opacity: .25;
					width: auto;
					margin: 0 auto;
					background-position: 50% 50%;
				}
			}
		}
	}
}


.sn {
	display: none;
	@include overlay(
		$p: fixed,
		$z: 1040
	);
	background: map-get($theme-colors, brand-2);
	color: $white;
	opacity: 0;
	overflow: auto;
	padding: #{map-get($spacing, xs) * 3};
	transition: opacity .25s ease;
	
	@include media-breakpoint-between(lg,xl){
		padding: map-get($spacing, xs);
	}
	
	@include media-breakpoint-down(md){
		padding: 0;
	}
	
	.c {
		&-col {
			@include media-breakpoint-up(md){
				@include make-col(6);
			}
		}
	}
	
	&__nav {
		@include media-breakpoint-down(md){
			padding-left: map-get($spacing, md);
			padding-right: map-get($spacing, md);
		}
	}
	
	&__contact {
		@include font-size($font-size-lg);
		color: map-get($theme-colors, brand-3);
		font-weight: $font-weight-bold;
		
		@include media-breakpoint-up(md){
			text-align: right;
		}
		@include media-breakpoint-down(md){
			padding-left: map-get($spacing, md);
			padding-right: map-get($spacing, md);
		}
		
		
		a {
			color: map-get($theme-colors, brand-3);
			
			&:hover {
				color: $white;
			}
		}
	}
	
	&-menu {
		@include list-reset;
		@include font-size(2.5rem);
		font-weight: $font-weight-bold; 
		
		&__link {
			position: relative;
			
			&:hover > a {
				color: $white;
			}
		}
		
		&__toggle {
			width: map-get($spacing, lg);
			height: 100%;
			position: absolute;
			right: 0;
			top:0;
			border:0;
			background: none;
			
			&::before,
			&::after {
				position: absolute;
				width: 1.5rem;
				height: .1875rem;
				top:50%;
				left:50%;
				background: map-get($theme-colors, brand-3);
				content: '';
				transition: $transition;
				
				.is-open & {
					transform: translate(-50%,-50%) rotate(180deg);
				}
				
			}
			&::before {
				transform: translate(-50%,-50%) rotate(-90deg);
			}
			&::after {
				transform: translate(-50%,-50%);
			}
			
			.is-open &,
			.is-active & {
				&::before,
				&::after {
					background: map-get($theme-colors, brand-1);
				}
			}
			li:not(.is-active) .sn-menu__link:hover &,
			li:not(.is-active) a:hover + &,
			li:not(.is-active) a:focus + &,
			&:hover {
				&::before,
				&::after {
					background: $white;
				}
			}
		}
		
		&__sub {
			@include font-size($font-size-lg);
			display: none;
			
			ul {
				@include list-reset;
			}
			
			.is-open & {
				display: block;
			}
			
			a {
				padding-left: 1em;
			}
		}
		
		a {
			display: block;
			color: map-get($theme-colors, brand-3);
			padding: .25em 0;
			
			&:hover,
			&:active,
			&:focus {
				color: $white;
			}
		}
		
		.is-active > a,
		.is-active > .sn-menu__link > a {
			color: map-get($theme-colors, brand-1);
			
			&:hover,
			&:focus {
				color: darken(map-get($theme-colors, brand-1), 7.5%);
			}
		}
		
		& + & {
			margin-top: 1em;
		}
	}
	
	&.--active {
		opacity: 1;
	}
	
	&::after {
		@include overlay(
			$p: fixed,
			$ttb: false,
			$ltr: false
		);
		background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 800' fill='"+ rgba($white, .05) +"'%3E%3Cpolygon points='800 71.86 728.14 0 699.86 0 800 100.14 800 71.86'/%3E%3Cpolygon points='800 171.86 628.14 0 599.86 0 800 200.14 800 171.86'/%3E%3Cpolygon points='800 271.86 528.14 0 499.86 0 800 300.14 800 271.86'/%3E%3Cpolygon points='800 371.86 428.14 0 399.86 0 800 400.14 800 371.86'/%3E%3Cpolygon points='800 471.86 328.14 0 299.86 0 800 500.14 800 471.86'/%3E%3Cpolygon points='800 571.86 228.14 0 199.86 0 800 600.14 800 571.86'/%3E%3Cpolygon points='800 671.86 128.14 0 99.86 0 800 700.14 800 671.86'/%3E%3Cpolygon points='800 800 800 771.86 28.14 0 0 0 0 0.14 799.86 800 800 800'/%3E%3C/svg%3E");
		background-position: right top;
		background-repeat: no-repeat;
		background-size: 50rem 50rem;
		content: '';
		pointer-events: none;
		transform: scaleY(-1);
		z-index: -1;
	}
}


//close button
.btn-icon {
	.sn & {
		position: fixed;
		top: map-get($spacing, xs);
		left: map-get($spacing, sm);
		height: 3.75rem;
		z-index: 2;

		@include media-breakpoint-up(xxl){
			top: #{map-get($spacing, xs) * 3};
			left: map-get($spacing, md);
		}

		@include media-breakpoint-down(md){
			top:0;
			left:0;
			border-width: .125rem;
		}
		
		.svg-icon {
			stroke: $black;
		}
		
		&:hover,
		&:active,
		&:focus {
			.svg-icon {
				stroke: $gray-800;
			}
		}

		&::after {
			width: 1.875rem;
			height: 1.875rem;
			
			@include media-breakpoint-down(md){
				display: none;
			}
		}
	}
}
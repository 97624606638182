//for sections and bg's
.--xs,
.--sm,
.--md,
.--lg,
.--xl {
	display: none;
}

.--xs {	
	@include media-breakpoint-only(xs){
		display: block;
	}
}
.--sm {	
	@include media-breakpoint-only(sm){
		display: block;
	}
}
.--md {	
	@include media-breakpoint-only(md){
		display: block;
	}
}
.--lg {	
	@include media-breakpoint-only(lg){
		display: block;
	}
}
.--xl {	
	@include media-breakpoint-up(xl){
		display: block;
	}
}
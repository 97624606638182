//## adjusting font-size to specific breakpoint (values is procent of the input size)
@mixin font-size(
    $size : $font-size-base,
    $xs : 50,
	$sm : 70,
    $md : 80,
    $lg : 90,
    $xl : 100,
    $xxl : 100,
    $xxxl : 100
){
	$default: $font-size-md;
	
	@include media-breakpoint-only(xs){
		@if(($size * ($xs / 100)) > $default){
			font-size: $size * ($xs / 100);
		}
		@else {
			font-size: $default;
		}
	}
	@include media-breakpoint-only(sm){
		@if(($size * ($sm / 100)) > $default){
			font-size: $size * ($sm / 100);
		}
		@else {
			font-size: $default;
		}
	}
	@include media-breakpoint-only(md){
		@if(($size * ($md / 100)) > $default){
			font-size: $size * ($md / 100);
		}
		@else {
			font-size: $default;
		}
	}
	@include media-breakpoint-only(lg){
		@if(($size * ($lg / 100)) > $default){
			font-size: $size * ($lg / 100);
		}
		@else {
			font-size: $default;
		}
	}
	@include media-breakpoint-only(xl){
		@if(($size * ($xl / 100)) > $default){
			font-size: $size * ($xl / 100);
		}
		@else {
			font-size: $default;
		}
	}
	@include media-breakpoint-only(xxl){
		@if(($size * ($xxl / 100)) > $default){
			font-size: $size * ($xxl / 100);
		}
		@else {
			font-size: $default;
		}
	}
	@include media-breakpoint-up(xxxl){
		@if(($size * ($xxxl / 100)) > $default){
			font-size: $size * ($xxxl / 100);
		}
		@else {
			font-size: $default;
		}
	}
}
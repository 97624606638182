.s--newsletter {
	@include py-spacing(
		$xs: 0,
		$lg: map-get($spacing, xl),
		$xl: map-get($spacing, xxl),
		$xxl: map-get($spacing, xxl)
	);
	
	.c {
		background: $gray-100;
		
		&-col {
			padding: map-get($spacing, sm);
			margin-bottom: 0;
			
			@include media-breakpoint-up(lg){
				padding: $grid-column;
				
				&:first-child {
					padding-right: #{$grid-column / 2};
				}
				&:last-child {
					padding-left: #{$grid-column / 2};
				}
			}
			
			@include media-breakpoint-between(sm, md){
				padding: map-get($spacing, md);
				
				&:last-child {
					padding-top: 0;
				}
			}
		}
	}

	
	.form {
		&-check {
			@include font-size($font-size-md);
			
			&__label {
				align-items: flex-start; 
			}
			
			&__tick {
				margin-top: .25em;
			}
		}
	}
}
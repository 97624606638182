.spotlight {
	display: flex;
	align-items: center;
	
	@include media-breakpoint-only(md){
		flex-direction: column;
		text-align: center;
	}
	
	@include media-breakpoint-only(xs){
		flex-direction: column;
		text-align: center;
	}

	&__media {
		@include make-col(4);
		padding-right: #{$spacer * 2};
		
		
		@include media-breakpoint-between(sm,md){
			max-width: map-get($spacing, xxl);
		}
		
		@include media-breakpoint-only(md){
			width: 100%;
			padding-right: 0;
			margin-bottom: $spacer;
		}
		
		@include media-breakpoint-only(xs){
			width: 100%;
			padding-right: 0;
			margin-bottom: $spacer;
		}

		.fig {
			margin:0;
			
			&__picture {
				background: $gray-200;
				border-radius: 50%;
				transform: translateZ(0);
			}

			&::after {
				@include overlay(
					$w: calc(100% + .6rem), 
					$h: calc(100% + .6rem),
					$x: -.3rem,
					$y: -.3rem,
					$z: -1
				);
				background-color: $primary;
				clip-path: circle(50% at top);
				-webkit-clip-path: circle(50% at top);
				content: '';
				pointer-events: none;
				transform: translateY(50%) rotate(0);
				transform-origin: 50% 0;
				transition: background $transTime $transEase,
							transform $transTime $transEase;

				@include customize(theme) {
					background-color: getValue(bgColor);
				}
			}
		}

		a:hover,
		a:active,
		a:focus {
			outline: none;
			
			.fig::after {
				background-color: darken($primary, 10%);
				transform: translateY(50%) rotate(180deg);

				@include customize(theme) {
					background-color: getValue(bgDark);
				}
			}
		}
	}
	
	&__body {
		//padding-left: #{$spacer};
		padding-right: #{$spacer};
		
		@include media-breakpoint-only(xs){
			padding-right: 0;
			max-width:  map-get($spacing, xxl) * 3;
		}
	}

	.title {
		@include font-size($h4-font-size);
	}

	a {
		display: block;
	}
}

.key {
	position: relative;
	padding-top: 100%;
	display: flex;
	align-items: center;
	color: $black;
	
	&-inner {
		@include overlay();
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		border: 3px solid $primary;
		transition: border $transTime $transEase;
		
		.--gray & {
			border-color: $gray-300;
		}
	}
	
	&__value {
		font-size: #{$font-size-base * 3};
		line-height: $headings-line-height;
		font-variant-numeric: lining-nums;
		font-weight: $font-weight-bold;
		display:block;
		width: 100%;
		margin-bottom: .17em;
	}
	
	&__desc {
		display:block;
		width: 100%;
		text-transform: uppercase;
		font-weight: $font-weight-bold;
		font-size: #{$font-size-base * .65};
		letter-spacing: .05em;
		line-height: $headings-line-height;
		min-height: map-get($spacing, md);
	}
	
	
	@at-root a#{&}{
		&:hover,
		&:focus {
			color: $black;
			
			.key-inner {
				border-color: darken($primary, 10%);
			}
		}
		
		&.--gray {
			&:hover,
			&:focus {
				.key-inner {
					border-color: $gray-500;
				}
			}
		}
	}
}
@mixin overlay(
	$p 		: 	absolute,	//position
	$h 		: 	100%,		//height
	$w 		: 	100%,		//width
	$x 		: 	0,			//x-axis
	$y 		: 	0,			//y-axis
	$z 		:	2,			//z-index
	$ltr 	: 	true,		//left to right (true/false)
	$ttb 	: 	true		//top to bottom (true/false)
){
	position: $p;
	width: $w;
	height: $h;
	z-index: $z;

	@if ($ttb == true) {
		top: $y;
	}@else {
		bottom: $y;
	}

	@if ($ltr == true) {
		left: $x;
	}@else {
		right: $x;
	}
}

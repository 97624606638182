//search in navigation
.search {
	@include my-spacing($only: bottom);
	display: flex;
	position: relative; 
	
	@include media-breakpoint-between(lg,xl){
		margin-left: 90px;
	}
	
	@include media-breakpoint-down(md){
		margin-left: map-get($spacing, md);
		margin-right: -#{map-get($spacing, sm)};
	}
	
	&__label {
		@include overlay(
			$w: auto
		);
		
		display: flex;
		align-items: center;
		margin-bottom: 0;
		padding: $spacer;
		
		.svg-icon.--outline {
			stroke: $black;
			opacity: .5;
		}
	}
	
	&__control {
		appearance: none;
		-webkit-appearance: none;
		background: $white;
		border:0;
		border-radius: 0;
		width: 100%;
		height: map-get($spacing, lg);
		padding-left: map-get($spacing, lg);
		padding-right: map-get($spacing, sm);
	}
	
	&__btn {
		@include font-size($font-size-md);		
		border:0;
		padding: 0 1em;
		min-width: #{$spacer * 6};
		overflow: hidden;
		text-indent: -9999px;
		font-weight: $font-weight-bold;
		transform-origin: 0 0;
		transform: scaleY(.5);
		transition: $transition;
		
		@include media-breakpoint-down(md){
			display: none;
		}
		
		&:hover,
		&:focus {
			height: auto;
			transform: scaleY(1);
			text-indent: 0;
			background: $primary;
		}
	}
}


//searchresults
.--search-results {
	.search,
	.results {
		max-width: map-get($grid-breakpoints, md);
		margin:0 auto;
	}
	
	
	.search {
		margin-bottom: 1em;
		
		&__control {
			background: $gray-100;
			border: 2px solid $gray-100;
			transition: background .25s ease;
			
			&:focus {
				background: $body-bg;
			}
		}
		&__btn {
			background: $primary;
			position: absolute;
			top:0;
			right:0;
			height: 100%;
			min-width: 0;
			transform: none;
			text-indent: 0;
			opacity: 0;
			pointer-events: none;
			
			&:focus {
				max-width: 100%;
				opacity: 1;
				pointer-events: auto;
			}
		}
	}
	
	.results {
		&-item {
			padding-top: #{$grid-gutter-width / 2};
			padding-bottom: #{$grid-gutter-width / 2};
			
			> a {
				&:hover .results-item__title {
					text-decoration: underline;
				}
			}
			
			&__title {
				@include font-size($h4-font-size, 80, 80);
				margin-bottom: 0;
				color: $fourth;
			}
			&__body {
				
			}
			
			&__url {
				font-size: $font-size-sm;
				color: $gray-500;
				font-style: normal;
				display: block;
			}
			
			&__date {
				color: $gray-600;
				margin-right: .25em;
				
				&::after {
					content: ' -'
				}
			}
		}
	}
}
.info {
	@include make-row;
	
	&-item {
		@include has-reveal;
		@include make-col-ready;
		margin-bottom: #{$grid-gutter-width};
		
		@include media-breakpoint-up(sm){
			@include make-col(6);
		}
		
		@include media-breakpoint-down(xs){
			text-align: center;
			
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	&__icon {
		margin-bottom: 1em;
		
		@include media-breakpoint-down(xs){
			max-width: 18rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	&__body {
		.title {
			@include font-size($h4-font-size);
			margin-bottom: .25em;
		}
		
		@include media-breakpoint-down(xs){
			max-width: 18rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
}


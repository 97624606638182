.lazyload,
.lazyloading {
	opacity: 0;

	@at-root img#{&}{
		//min-width: 100%;
		//transform: translateY(1rem);
		
		a & {
			transform: none;
		}
	}
}

.lazyloaded {
	opacity: 1;
	transition: opacity ($transTime*2) $transEase $transTime;

	@at-root img#{&}{
		//transform: translateY(0);
		transition:
			opacity ($transTime*2) $transEase .1s,
			transform ($transTime*2) $transEase .1s;
	}
}

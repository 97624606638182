.partners {
	@include list-flex(
		$align: center,
		//$justify: center,
		$justify: space-between
	);
	margin-left: -#{$grid-gutter-width / 2};
	margin-right: -#{$grid-gutter-width / 2};
	position: relative;
	
	@include media-breakpoint-only(xs){
		justify-content: center;
	}
	
	&__item {
		@include has-reveal;
		//@include make-col(2);
		max-width: 20%;
		padding: #{$grid-gutter-width / 2};
		text-align: center;
		
		@include media-breakpoint-only(xs){
			max-width: 50%;
		}
		
		
		img {
			display: block;
			max-height: 4rem; //64px
		}
		
		> * {
			opacity: .3;
			filter: grayscale(100%);
			transition: opacity $transTime $transEase;
		}
		
		&:hover > * {
			opacity: 1;
		}
	}
}
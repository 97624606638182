.article-image {
	@include my-spacing;
	position: relative;
	
	@include media-breakpoint-up(lg){
		margin-left: -#{map-get($spacing, xxl)};
		margin-right: -#{map-get($spacing, xxl)};
	}
	
	@include media-breakpoint-only(md){
		margin-left: -#{map-get($spacing, lg)};
		margin-right: -#{map-get($spacing, lg)};
	}
	
	&,
	&--left,
	&--right {
		> img {
			position: relative;
			width: 100% !important;
			z-index: 2;
		}
		
		&.has-reveal {
			.js & {
				opacity: 0;
				transition: opacity .25s ease .15s;
			}
		}
		&.is-animated {
			.js & {
				opacity: 1;				
			}
		}
		
		&::before,
		&::after {
			content: '';
			pointer-events: none;
		}
		&::before {
			background: $gray-200;
			z-index: 1;
		}
		&::after {
			background-color: $primary;
			transform: rotate(90deg);
			z-index: 3;
		
			@include customize(theme) {
				background-color: getValue(bgColor);
			}
		}
	}
	
	&--left,
	&--right {
		//@include has-reveal($transform: true);
		position: relative;
		margin-top: .5em;
		margin-bottom: 2em;
		
		@include media-breakpoint-down(sm){
			margin-bottom: 1.5em;
		}
		
		@include media-breakpoint-only(xs){
			margin-top: 0;
		}
	}
	
	&--left,
	&--right {
		@include media-breakpoint-up(sm){
			max-width: 45%;
		}
		
		& + * {
			margin-top: 0;
		}
	}
	
	&--left {
		float: left;
		margin-right: 2em;
		
		@include media-breakpoint-down(sm){
			margin-right: 1.5em;
		}
		
		@include media-breakpoint-only(xs){
			float: none;
			margin-right: 0;
		}
		
		&::before {
			@include overlay(
				$x: -#{$spacer},
				$y: -#{$spacer},
				$w: 50%,
				$h: 25%,
				$ttb: false
			);
		}
		&::after {
			@include overlay(
				$x: -#{$spacer},
				$y: #{$spacer},
				$w: #{$spacer * 2},
				$h: #{$spacer * .2},
				$ltr: true
			);
			transform: none;
		}
	}
	
	&--right {
		float: right;
		margin-left: 2em;
						
		@include media-breakpoint-down(sm){
			margin-left: 1.5em;
		}
		
		@include media-breakpoint-only(xs){
			float: none;
			margin-left: 0;
		}
		
		&::before {
			@include overlay(
				$x: -#{$spacer},
				$y: -#{$spacer},
				$w: 50%,
				$h: 25%,
				$ttb: true,
				$ltr: false
			);
		}
		&::after {
			@include overlay(
				$x: -#{$spacer},
				$y: #{$spacer},
				$w: #{$spacer * 2},
				$h: #{$spacer * .2},
				$ttb: false,
				$ltr: false
			);
			transform: none;
		}
	}
	
	

	&::before {
		@include overlay(
			$x: -#{$spacer * 2},
			$y: -#{$spacer * 2},
			$w: 50%,
			$h: 25%,
			$ttb: false
		);

		@include media-breakpoint-down(xl){
			left: -#{$spacer};
			bottom: -#{$spacer};
		}
	}
	&::after {
		@include overlay(
			$x: #{$spacer},
			$y: -#{$spacer * .25},
			$w: #{$spacer * 4},
			$h: #{$spacer * .25},
			$ltr: false
		);

		@include media-breakpoint-down(xl){
			width: #{$spacer * 2};
			height: #{$spacer * .125};
		}
	}
}
.--article-block {
	//--- LAYOUT
	.c-row {
		align-items: center;
		flex-direction: column;
		
		@include media-breakpoint-up(md){
			flex-direction: row;
		}
	}
	
	.c-col,
	.c-media {
		@include make-col-ready;
		@include make-col(12);
		
		@include media-breakpoint-up(md){
			@include make-col(6);
		}
	}
	
	.c-media {
		@include has-reveal;
		text-align: center;
		margin-bottom: $grid-gutter-width;
		
		.fig {
			margin-bottom: 0;
		}
		
		a {
			cursor: zoom-in;
		}
		
		&:not(.carousel){
			.fig__picture {
				overflow: hidden;
			}
			
			&:hover {
				.fig__object {
					transform: scale(1.1);
				}
			}
		}
	}
	
	.c-col {
		
		@include media-breakpoint-up(lg){
			padding-left: #{$grid-column / 2};
			padding-right: #{$grid-column / 2};
		}
	}
	
	//--- CONTENT
	
	.fig {
		display: inline-block;
					
		@include media-breakpoint-up(sm){
			margin-bottom: #{$spacer * 2};
		}

		&__picture {
			position: relative;
			z-index: 2;
		}

		&__object {
			max-height: map-get($grid-breakpoints, sm);
		}
			
		&::before,
		&::after {
			content: '';
		}
		&::before {
			@include overlay(
				$x: -$spacer,
				$y: -$spacer,
				$w: 50%,
				$h: 75%,
				$ttb: false
			);
			background: $gray-200;
			z-index: 1;
			
			@include media-breakpoint-down(sm){
				left: -#{$spacer / 2};
				bottom: -#{$spacer / 2};
			}
		}
		&::after {
			@include overlay(
				$x: -$spacer,
				$y: $spacer,
				$w: #{$spacer * 4},
				$h: #{$spacer / 4},
				$ltr: false
			);
			background-color: $primary;
			z-index: 2;

			@include customize(theme) {
				background-color: getValue(bgColor);
			}
		}
	}

	.title {
		
	}
	

	//--- DIRECTION MODIFIER

	&[data-dir="ltr"]{
		.c-media {
			@include media-breakpoint-up(lg){
				padding-right: #{$grid-column / 2};
			}
		}
	}
	
	&[data-dir="rtl"]{
		.c-row {
			flex-direction: column;
			
			@include media-breakpoint-up(md){
				flex-direction: row-reverse;
			}
		}
		
		.c-media {
			@include media-breakpoint-up(lg){
				padding-left: #{$grid-column / 2};
			}
		}
	}
}

.carousel {
	position: relative;
	overflow: hidden;
	margin-bottom: 0;
	padding-bottom: $grid-gutter-width;
	
	&-indicators {
		margin-left: 0;
		margin-right: 0;
		
		> li {
			background-color: $primary;
			opacity: .25;

			&:hover,
			&.active {
				opacity: 1;
				filter: drop-shadow(0 0 .25rem $primary);
			}
		}
	}
	
	&-wrapper {
		position: relative;
	}
	
	&-item {
		transition: opacity .5s ease, transform .5s ease;
		position: relative;
		padding-top: 100%;
		
		&,
		&.active.carousel-item-left,
		&.active.carousel-item-right {
			opacity: 0;
		}
		
		&-prev.carousel-item-right,
		&-next.carousel-item-left,
		&.active {
			opacity: 1;
		}
		
		a {
			@include overlay;
		}
		
		.fig {
			height: 100%;
			
			&::before,
			&::after {
				display: none;
			}
			
			&__picture {
				display: inline-block;
				height: 100%;
				overflow: hidden;
			}
			
			&__object {
				position: relative;
				top:50%;
				left:50%;
				transform: translate(-50%,-50%);
				max-height: 100%;
			}
		}
	}
	
	&-inner {
		//@include overlay;		
		//display: flex;
		//align-items: center;
		overflow: visible;
	}
	
	&-control {
		position: absolute;
		top:0;
		height: 100%;
		width: 15%;
		z-index: 20;
		background: none;
		border:0;
		min-width: 3rem;
		padding: 0;
		
		&:focus {
			outline: none;
		}
		
		@media (hover: none) and (pointer: coarse) {
			display: none;
		}
		
		&.--prev {
			left:-1rem;
		}
		&.--next {
			right:-1rem;
		}
		
		&__icon {
			display: block;
			width: 3rem;
			height: 3rem;
			background: $primary;
			fill: none;
			stroke: $black;
			padding: .5rem;
			transition: background .25s ease;
			
			.--next & {
				margin-left: auto;
			}
			.--prev & {
				margin-left: 0;
			}
			
			.carousel-control:hover &,
			.carousel-control:focus & {
				background: darken($primary, 10%);
			}
			.carousel-control:active & {
				background: darken($primary, 15%);
			}
		}
	}
}
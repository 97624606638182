.s--facts {
	
	@include media-breakpoint-up(sm){
		padding-left:  #{$grid-gutter-width};
		padding-right: #{$grid-gutter-width};
	}
	
	@include media-breakpoint-down(xs){
		padding-left:  #{$grid-gutter-width / 2};
		padding-right: #{$grid-gutter-width / 2};
	}
	
	.c {
		background: $primary;
		@include py-spacing;
		
		&-header,
		&-col {
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 0;
			
			@include media-breakpoint-up(lg){
				@include make-col(8);
				@include make-col-offset(2);
			}

			@include media-breakpoint-only(md){
				@include make-col(10);
				@include make-col-offset(1);
			}
		}
		
		&-row {
			margin: 0;
		}
	}
	
	.title {
		margin-bottom: 0;
		
		&.--line::after {
			border-color: $black;
		}
	}
	
	ul > li > strong {
		@include media-breakpoint-only(xs){
			display: block;
		}
	}
	
	p > a:not([class]),
	span > a:not([class]),
	a:not([class]) {
		
		&::after {
			background: rgba(darken($primary, 20%), 1);
		}
		
		//&:hover,
		//&:active,
		//&:focus {
		//	color: $primary;
		//}
	}
}
// You can customize Simplelightbox with the following variables:

$sl-font-family: $font-family-base !default;
$sl-overlay-background: $black !default;
$sl-overlay-opacity: .5 !default;
$sl-navigation-color: #000 !default;

$sl-caption-color: #fff !default;
$sl-caption-background: linear-gradient(0, rgba(0,0,0,.7) -10%, rgba(0,0,0,0) 100%) !default;

$sl-counter-fontsize: 1rem !default;

$sl-close-fontsize: 1rem !default;

$sl-breakpoint-medium: 35.5em !default; // 568px, when 1em == 16px
$sl-breakpoint-large:	50em !default;	 // 800px, when 1em == 16px

$sl-arrow-fontsize-small:	2rem !default;
$sl-arrow-fontsize-medium: 3rem !default;
$sl-arrow-fontsize-large:	3rem !default;

$sl-img-border-small:	0 none !default;
$sl-img-border-medium: 0 none !default;
$sl-img-border-large:	0 none !default;

$sl-iframe-border-small:	0 none !default;
$sl-iframe-border-medium: 0 none !default;
$sl-iframe-border-large:	0 none !default;

$add-vendor-prefixes: true !default;

.hidden-scroll,
.has-lightbox {
	overflow: hidden;
}
.sl-overlay {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: $sl-overlay-background;
	opacity: $sl-overlay-opacity;
	display: none;
	z-index: 1040;
}
.sl-wrapper {
	z-index: 1050;
	
	button {
		border: 0 none;
		background: transparent;
		font-size: 28px;
		padding: 0;
		cursor: pointer;
		transition: $transition;
		
		&:hover {
			opacity: 0.7;
		}
	}

	.sl-close {
		display: none;
		position: fixed;
		right: #{$spacer};
		top: #{$spacer};
		z-index: 1060;
		padding:0;
		font-family: $sl-font-family;
		color: $sl-navigation-color;
		font-size: $sl-close-fontsize;
		background: $primary;
		line-height: 1;
		padding: $spacer;
		
		svg {
			stroke: $black;
		}

		&:focus {
			outline: none;
		}
	}

	.sl-counter {
		display: none;
		position: fixed;
		top: #{$spacer};
		left: #{$spacer};
		z-index: 1060;
		color: $sl-navigation-color;
		font-size: $sl-counter-fontsize;
	}

	.sl-navigation {
		width: 100%;
		display: none;
		
		button {
			position: fixed;
			top: 50%;
			transform: translateY(-50%);
			line-height: 1;
			font-size: 1rem;
			padding: $spacer;
			display: block;
			z-index: 1060;
			font-family: $sl-font-family;
			color: $sl-navigation-color;
			background: $primary;
			
			svg {
				stroke: $black;
			}
			
			&.sl-next {
				right: $spacer;
			}

			&.sl-prev {
				left: $spacer;
			}

			&:focus {
				outline: none;
			}

			@media (max-width: $sl-breakpoint-medium) {
				display: none;
			}
		}
	}

	.sl-image {
		position: fixed;
		@if $add-vendor-prefixes {
			-ms-touch-action: none;
		}
		touch-action: none;
		z-index: 1041;
		
		img {
			margin: 0;
			padding: 0;
			display: block;
			border: $sl-img-border-small;
			width: 100%;
			height: auto;
			@media (min-width: $sl-breakpoint-medium) {
				border: $sl-img-border-medium;
			}
			@media (min-width: $sl-breakpoint-large) {
				border: $sl-img-border-large;
			}

		}
		
		iframe {
			background: #000;
			border: $sl-iframe-border-small;
			@media (min-width: $sl-breakpoint-medium) {
				border: $sl-iframe-border-medium;
			}
			@media (min-width: $sl-breakpoint-large) {
				border: $sl-iframe-border-large;
			}
		}
		.sl-caption {
			@include font-size($font-size-base);
			display: none;
			padding: #{$spacer * 2} $spacer #{$spacer / 2} ;
			color: $sl-caption-color;
			background: $sl-caption-background;
			font-weight: $font-weight-bold;
			text-shadow: 0 2px 3px rgba(0,0,0,.2);
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;

			&.pos-top {
				bottom: auto;
				top: 0;
			}

			&.pos-outside {
				bottom: auto;
			}
		}

		.sl-download {
			display: none;
			position: absolute;
			bottom: 5px;
			right: 5px;
			color: $sl-navigation-color;
			z-index: 1060;
		}
	}
}

.sl-spinner {
		display: none;
		border: 5px solid #333;
		border-radius: 40px;
		height: 40px;
		left: 50%;
		margin: -20px 0 0 -20px;
		opacity: 0;
		position: fixed;
		top: 50%;
		width: 40px;
		z-index: 1007;
		@if $add-vendor-prefixes {
			-webkit-animation: pulsate 1s ease-out infinite;
			-moz-animation: pulsate 1s ease-out infinite;
			-ms-animation: pulsate 1s ease-out infinite;
			-o-animation: pulsate 1s ease-out infinite;
		}
		animation: pulsate 1s ease-out infinite;
}

.sl-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}

.sl-transition {
	@if $add-vendor-prefixes {
		transition: -moz-transform ease 200ms;
		transition: -ms-transform ease 200ms;
		transition: -o-transform ease 200ms;
		transition: -webkit-transform ease 200ms;
	}
	transition: transform ease 200ms;
}

@-webkit-keyframes pulsate{
		0% {
			transform: scale(.1);
			opacity: 0.0;
		}
		50% {
			opacity: 1;
		}
		100% {
			transform: scale(1.2);
			opacity: 0;
		}
}
@keyframes pulsate {
		0% {
			transform: scale(.1);
			opacity: 0.0;
		}
		50% {
			opacity: 1;
		}
		100% {
			transform: scale(1.2);
			opacity: 0;
		}
}
@if $add-vendor-prefixes {
	@-moz-keyframes pulsate{
			0% {
				transform: scale(.1);
				opacity: 0.0;
			}
			50% {
				opacity: 1;
			}
			100% {
				transform: scale(1.2);
				opacity: 0;
			}
	}

	@-o-keyframes pulsate{
			0% {
				transform: scale(.1);
				opacity: 0.0;
			}
			50% {
				opacity: 1;
			}
			100% {
				transform: scale(1.2);
				opacity: 0;
			}
	}

	@-ms-keyframes pulsate{
			0% {
				transform: scale(.1);
				opacity: 0.0;
			}
			50% {
				opacity: 1;
			}
			100% {
				transform: scale(1.2);
				opacity: 0;
			}
	}
}

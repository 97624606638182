.article {
	@include py-spacing($nav: true);
	position: relative;
	
	@include media-breakpoint-up(sm){
		padding-left:  #{$grid-gutter-width};
		padding-right: #{$grid-gutter-width};
	}
	
	@include media-breakpoint-down(xs){
		padding-left:  #{$grid-gutter-width / 2};
		padding-right: #{$grid-gutter-width / 2};
	}
	
	.hero + & {
		@include py-spacing;
	}
	
	&-content {
		@include make-container;
		max-width: $container-max-width;
		position: relative;
		margin: auto;
		
		@include media-breakpoint-down(md){
			padding-left: 0;
			padding-right: 0;
		}
	}
	
	&-header,
	&-body {
		@include media-breakpoint-up(lg){
			@include make-col(8);
			@include make-col-offset(2);
		}

		@include media-breakpoint-only(md){
			@include make-col(10);
			@include make-col-offset(1);
		}
	}
	
	&-header {
		padding-right: 5%;
		
		&__title {
			line-height: 1.2;
			margin-top: 0;
			margin-bottom: 2rem;
		}
		
		.tag {
			margin-bottom: 1em;
		}
	}
	
	&__date {
		display: inline-block;
		background: $gray-100;
		padding: .4em .6em;
		line-height: $line-height-sm;
		font-weight: $font-weight-bold;
		font-size: $font-size-md;
		
		@include media-breakpoint-down(md){
			font-size: $font-size-sm;
		}
		
		.article-header__title + & {
			position: relative;
			margin-bottom: 2rem;
		}
	}
	
}
.fig {
	position: relative;
	//margin-bottom: 0;

	> a {
		display: block;
		overflow: hidden;
	}

	&__picture {
		display: block;

		.fig[class*=fig--] & {
			position: relative;
			overflow: hidden;
		}
		.fig--1by1 & {
			padding-top: 100%;
		}
		.fig--2by1 & {
			padding-top: 50%;
		}
		.fig--4by3 & {
			padding-top: 75%;
		}
		.fig--16by10 & {
			padding-top: 62.5%;
		}
		.fig--16by9 & {
			padding-top: 56.25%;
		}
	
		a > & {
			transform: scale(1);
			transition: transform 10s ease;
		}
		
		a:hover > &,
		a:active > &,
		a:focus > & {
			transform: scale(1.2);
		}
	}
	
	&__object {
		//width: 100%;
		
		.fig[class*=fig--] & {
			@include overlay($h: auto);
		}
	}

	&__caption {
		font-size: $font-size-sm;
		color: $gray-600;
		padding-top: .5rem;

		@include media-breakpoint-only(xs){
			font-size: $font-size-xs;
		}
	}
}

.iframe-wrapper {
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;

	&:not(:last-child){
		margin-bottom: 2em;

		@include media-breakpoint-down(sm){
			margin-bottom: 1em;
		}
	}

	> iframe {
		position: absolute;
		width: 100%;
		height: 100%;
		border:0;
		overflow: hidden;
	}

	&.--facebook,
	&.--spotify {
		max-width: 440px;
		padding-bottom: 75%;

		@include media-breakpoint-down(md){
			padding-bottom: 100%;
			max-width: none;
		}
	}
}

.video-background {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;

	iframe {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100vw;
		height: 100vh;
		transform: translate(-50%, -50%);

		@media (min-aspect-ratio: 16/9) {
			/* height = 100 * (9 / 16) = 56.25 */
			height: 56.25vw;
		}
		@media (max-aspect-ratio: 16/9) {
			/* width = 100 / (9 / 16) = 177.777777 */
			width: 177.78vh;
		}
	}
}

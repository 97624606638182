.member {
	@include has-reveal;
	@include make-col-ready;
	margin-bottom: $grid-gutter-width;
		
	@include media-breakpoint-up(lg){
		@include make-col(3);
	}
	@include media-breakpoint-only(md){
		@include make-col(4);
	}
	@include media-breakpoint-only(sm){
		@include make-col(6);
	}
	
	@include media-breakpoint-only(xs){
		margin-bottom: 1rem;
	}
	
	&,
	&-link {
		display: block;
		
		@include media-breakpoint-only(xs){
			display: flex;
			align-items: center;
			width: 100%;
		}
	}
	
	&-media {
		@include media-breakpoint-only(xs){
			position: relative;
			width: #{$spacer * 4};
			height: #{$spacer * 4};
			overflow: hidden;
		}
		
		.fig {
			@include media-breakpoint-only(xs){
				@include overlay;
				margin-bottom: 0;
			}
			
			&__picture {
				transition: filter .25s ease;
				filter: grayscale(100%);
				
				.member:hover &,
				.member:focus-within & {
					filter: grayscale(0%);
				}
			}
			
			&__object {
				width: 100%;
			}
			
			&::before {
				@include overlay;
				content: '';
				background: $primary;
				//opacity: .9;
				mix-blend-mode: multiply;
				transform-origin: 0 0;
				transition: transform .25s ease;
				
				@include customize(theme) {
					background: getValue(bgColor);
				}
				
				.member:hover &,
				.member:focus-within & {
					transform-origin: 100% 0;
					transform: scaleX(0);
				}
			}
		}
	}
	&-body {
		@include media-breakpoint-only(xs){
			flex: 2;
			padding-left: $spacer;
		}
	}
	
	&__name {
		margin-bottom: 0;
	}
	&__title {
		color: $gray-500;
		font-size: $font-size-sm;
		font-weight: $font-weight-bold;
		letter-spacing: .03em;
		text-transform: uppercase;
		
		//@include customize(theme) {
		//	color: getValue(textColor);
		//}
	}
	
	//contains member(s)
	&-group {
		@include make-row;
	}
}
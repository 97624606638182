@mixin has-reveal(
	$opacity 	: true,
	$transform 	: false,
	$grid 		: false,
	$time 		: 1s,
	$delay		: 0s,
	$transition : ease
) {
	&.has-reveal {
		.js & {
			@if($opacity == true){
				opacity: 0;
			}
			@if($transform == true){
				transform: translateY(1rem);
			}
			@if(($opacity == true) or ($transform == true)){
				transition: opacity $time $transition $delay,
							transform $time $transition $delay;
			}

			//when animated
			&.is-animated {
				@if($opacity == true){
					opacity: 1;
				}
				@if($transform == true){
					transform: translateY(0);
				}

				@if($grid == false){
					@for $i from 2 through 6 {
						&:nth-child(#{$i}){
							transition-delay: ((.12s * $i)-.2s);
						}
					}
				}
			}
		}
	}
}

@mixin parent-reveal($trans-from : "", $trans-to : "", $trans : "opacity .75s ease 0s") {
	.has-reveal & {
		opacity: 0;
		
		@if($trans-from != ""){
			transform: unquote($trans-from);
		}
	}
	
	.has-reveal.is-animated & {
		opacity: 1;
		transition: unquote($trans);
		
		@if($trans-to != ""){
			transform: unquote($trans-to);	
		}
	}
}


* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

body {
	//-ms-overflow-style: scrollbar;
}

// text-selection
::-moz-selection { 
	background: $primary;
	color: $black;
}
::selection { 
	background: $primary;
	color: $black;
}

mark {
	background: rgba($primary, .2);
	transition: background .25s ease;
	//font-weight: $font-weight-bold;
	
	&:hover {
		background: rgba($primary, .4);
	}
}

//link

a {
	@include customize(theme) {
		color: getValue(textColor);
	}
	
	display: inline-block;
	transition:
		background .25s ease,
		border-color .25s ease, 
		color .25s ease,
		opacity .25s ease;
		
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
		
		@include customize(theme) {
			color: getValue(textDark);
		}
	}
}

p > a:not([class]),
span > a:not([class]) {
	position: relative;
	font-weight: 500;
	color: inherit;
	padding: 0 .17em;
	z-index: 2;

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		background: $primary;
		position: absolute;
		bottom:.1em;
		left:0;
		opacity: 1;
		mix-blend-mode: multiply;
		transform-origin: 0 100%;
		transform: scaleY(.3);
		transition: $transition;
		z-index: -1;

		@include customize(theme) {
			background: getValue(bgColor);
		}
	}

	&:hover::after,
	&:active::after {
		transform: scaleY(.8);
	}
}

b,
strong {
	font-weight: $font-weight-bold;
}


img {
	height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
    -ms-interpolation-mode: bicubic;
	
	//&[style*="width"],
	//&[style*="height"]{
	//	width: auto !important;
	//	height: auto !important;
	//}
}


.list-unstyled {
	li {
		margin: .25em 0;
	}
}
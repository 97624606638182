.modal {
	&.--gallery & {
		/*
		&-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
		
		&-dialog {
			max-width: 100%;
			max-height: 100%;
			width: 100%;
			height: 100%;
			margin:0;
			text-align: center;
			transform: none;
			overflow: hidden;
		}
		
		&-content {
			position: absolute;
			top:50%;
			left:50%;
			transform: translate(-50%,-50%);
			
			background: none;
			border:0;
			border-radius: 0;
			height: 100%;
			width: 100%;
			
			align-items: center;
			
			.fig {
				display: inline-block;
				margin-bottom: 0;
				height: 100%;
				
				&__picture {
					display: inline-block;
					height: 100%;
				}
				
				&__object {
					max-height: 100%;
					top: 50%;
					position: relative;
					transform: translateY(-50%);
				}
			}
			
			
		}
		
		&-body {
			display: inline-block;
			height: 100%;
			max-width: map-get($grid-breakpoints, xl);
			opacity: 0;
			transform: translateY(2rem);
			transition: transform .25s ease,
						opacity .25s ease;
			
			@at-root .modal.show.--gallery .modal-body {
				transform: translateY(0);
				opacity: 1;
			}
		}
		*/
		
		&-wrapper {
			width: 100%;
			height: 100%;
		}
		
		&-dialog {
			margin:0;
			max-width: none;
			width: 100%;
			height: 100%;
		}
		
		&-content {
			border:0;
			background: none;
			border-radius: 0;
			width: 100%;
			height: 100%;
			max-width: 500px;
			max-height: 500px;
			margin: 0 auto;
			z-index: 1;
		}
		
		&-body {
			padding: 0;
			padding-top: 100%;
			
			.fig {
				@include overlay;
				margin-bottom: 0;
				
				&__picture {
					height: 100%;
				}
				
				&__object {
					max-height: 100%;
				}
				
				&__caption {
					position: absolute;
					left:0;
					bottom:0;
					z-index: 2;
					color: $white;
					font-weight: $font-weight-bold;
				}
			}
		}
		
		&-pager {
			@include overlay(
				$p: fixed,
				$w: 50%
			);
			background: none;
			border:0;
			outline: none;
			padding: map-get($spacing, lg);
			
			@include media-breakpoint-between(md,lg){
				padding: map-get($spacing, md);
			}
			
			.svg-icon {
				stroke: $white;
				border: 2px solid $white;
				padding: 8px;
				width: 48px;
				height: 48px;
				
				@include media-breakpoint-down(sm){
					width: 32px;
					height: 32px;
					border:0;
					padding:0;
					display: none;
				}
			}
			
			&.--prev {
				text-align: left;
				
			}
			&.--next {
				left: auto;
				right: 0;
				text-align: right;
			}
		}
	}
}
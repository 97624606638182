.c {
	@include make-container;
	max-width: $container-max-width;
	position: relative;
		
	@include media-breakpoint-down(xs){
		padding-left: #{$grid-gutter-width / 2};
		padding-right: #{$grid-gutter-width / 2};
	}
	
	&-row {
		@include make-row;
		
		@include media-breakpoint-down(xs){
			margin-left: -#{$grid-gutter-width / 2};
			margin-right: -#{$grid-gutter-width / 2};
		}
		
		&.--center {
			justify-content: center;
		}
	}
	
	&-col {
		@include make-col-ready;
		@include has-reveal;
		margin-bottom: $grid-gutter-width;
		
		@include media-breakpoint-down(sm){
			&:last-child {
				margin-bottom: 0;
			}
		}
		
		@include media-breakpoint-down(xs){
			padding-left: #{$grid-gutter-width / 2};
			padding-right: #{$grid-gutter-width / 2};
		}
	}
	
	&-header {
		//display: flex;
		//align-items: flex-end;
		margin-bottom: map-get($spacing, md);
		
		@include media-breakpoint-down(xs){
			margin-bottom: map-get($spacing, sm);	
		}
		
		&__title {
			flex: 2;
			margin-bottom: 0;
			
			&::after {
				border-bottom: #{$spacer / 4} solid $primary;
				content: '';
				display: block;
				margin-top: .5em;
				margin-bottom: .5em;
				position: relative;
				width: #{map-get($spacing, lg)};

				@include customize(theme) {
					border-color: getValue(bgColor);
				}
			}
		}
		
		&.--center {
			//display: block;
			//align-items: flex-start;
			text-align: center;
			
			.c-header__title::after {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
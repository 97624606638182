.card {
	.card__title {
		margin:0;
	}
	
	//MODIFIER
	
	.--feed & {
		@include make-col-ready;
		max-width: 50%;
		padding: #{$grid-gutter-width / 2};
		
		@include media-breakpoint-down(md){
			width: 25%;
			min-width: 280px;
			max-width: none;
		}
		
		&-inner {
			position: relative;
			display: block;
			background: $white;
			width: 100%;
			padding-top:100%;
			box-shadow: 0 4px 24px rgba(0,0,0,.1);
			
			border: 4px solid transparent;
			transition:  $transition;
			
			&:hover,
			&:active,
			&:focus {
				box-shadow: 0 4px 16px rgba(0,0,0,.2);
				transform: scale(1.05);
				
				@include customize(theme) {
					border-color: getValue(bgColor);
				}
				
				.card__tag {
					border-color: transparent;
				}
				
			}
		}
		
		&__tag {
			font-size: $font-size-xs;
			font-weight: $font-weight-bold;
			text-transform: uppercase;
			top: -4px; //same as border
			left: map-get($spacing, sm);
			
			border-top: 4px solid $black;
			padding-top: map-get($spacing, sm);
			position: absolute;
			color: $gray-700;
			
			transition: border $transTime $transEase;
			
			@include customize(theme) {
				border-color: getValue(bgColor);
			}
		}
		
		&__content {
			left:0;
			bottom:0;
			padding: map-get($spacing, sm);
			position: absolute;
		}
		
		&__date {
			@include font-size($font-size-md);
			display: block;
			margin-bottom: .25em;
			color: $gray-700;
		}
		
		&__title {
			@include font-size($h5-font-size);
		}
		
	}
}

//group of cards
.cards {
	@include make-row;
	
	&.--x4 {
		.card {
			width: 100%;
			min-width: 0;
			
			@include media-breakpoint-up(xl){
				width: 25%;
			}
			@include media-breakpoint-between(md,lg){
				width: 33%;
			}
			@include media-breakpoint-only(sm){
				width: 50%;
			}
		}
	} 
	
	&.--scrollable {
		@include media-breakpoint-down(md){
			@include hide-scrollbar;
			overflow: auto;
			flex-flow: row nowrap;
			padding-bottom: #{$grid-gutter-width / 2};
			
		}
		@include media-breakpoint-between(sm,md){
			margin-left: -$grid-gutter-width;
			margin-right: -$grid-gutter-width;
			padding-left: #{$grid-gutter-width / 2};
			padding-right: #{$grid-gutter-width / 2};
		}
	}
}
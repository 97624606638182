.gallery {
	@include make-row;
	
	&-item {
		padding: 0 map-get($spacing, sm) map-get($spacing, md);
		transition: top .25s ease,
					left .25s ease;
		will-change: contents;
		
		.fig {
			margin-bottom: 0;
			overflow: hidden;
			
			&__object {
				width: 100%;
				transition: transform 1.5s $easeInOutQuart,
							filter 1s ease;
				
				&.lazyload,
				&.lazyloading {
					transform: scale(1.1);
					filter:  blur(10px);
				}
			}
		}
		
		a {
			display: block;
			cursor: zoom-in;
			
			.fig__picture {
				transition: transform .5s ease;
			}
			
			&:hover {
				.fig__picture {
					transform: scale(1.1);
				}
			}
		}

	}
}
.btn {
	box-shadow: none;
	height: 3.5rem;
	position: relative;
	transition: $transition;
		
	@include media-breakpoint-down(sm){
		height: 3rem;
		border-width: .125rem;
		font-size: $font-size-xs;
	}
	
	@include media-breakpoint-down(xs){
		display: table;
	}

	
	//&:hover,
	&:active,
	&:focus {
		&::after {
			top: 50%;
			transform: translateY(0);
		}
	}
	
	& + & {
		@include media-breakpoint-up(sm){
			margin-left: #{$spacer * 2};
		}
		
		@include media-breakpoint-down(xs){
			margin-top: $spacer;
		}
	}
	
	&::after {
		position: absolute;
		top: 0;
		left: 100%;
		content: '';
		width: 1.75rem;
		height: calc(50% + .1875rem);
		z-index: 2;
		transform: translateY(-.1875rem);
		transition: $transition;
		pointer-events: none;
		
		@include media-breakpoint-down(sm){
			transform: translateY(-.125rem);
			width: 1.5rem;
			height: calc(50% + .125rem);
		}
	}
	
	&.--outline::after {
		content: none;
	}
		
	&[disabled]::after,
	&.--outline[disabled]::after {
		top: 0;
		transform: translateY(-.1875rem);
	}
}

//THEME'COLOR only

.btn {
	background: $primary;
	border-color: transparent;
	color: $black;
	transition: $transition;	
	
	@include customize(theme) {
		background: getValue(bgColor);
		border-color: getValue(bgColor);
		color: $white;
	}
	
	&::after {
		background: $primary;

		@include customize(theme) {
			background: getValue(bgColor);
		}
	}
	
	&:hover,
	&:active,
	&:focus {
		background: darken($primary, 7.5%);
		color: $black;
		
		@include customize(theme) {
			background: getValue(bgDark);
			border-color: getValue(bgDark);
			color: $white;
		}
		
		&::after {
			background: darken($primary, 7.5%);
			
			@include customize(theme) {
				background: getValue(bgDark);
			}
		}
	}
	
	
	//OUTLINE "GHOST" BUTTON
	
	&.--outline {
		background: transparent;
		border-color: $primary;
		color: $black;
		
		@include customize(theme) {
			background: transparent;
			border-color: getValue(bgColor);
			color: $black;
		}
		
		&:hover,
		&:focus {
			background: darken($primary, 7.5%);
			border-color: darken($primary, 7.5%);
			color: $black;

			@include customize(theme) {
				background: getValue(bgDark);
				border-color: getValue(bgDark);
				color: $white;
			}
		}
	}
	
	&[disabled],
	&.--outline[disabled]{
		background: $gray-200;
		border-color: $gray-200;
		color: $gray-600;
		cursor: not-allowed;
		
		&:hover,
		&:active,
		&:focus {
			background: $gray-200;
			border-color: $gray-200;
			color: $gray-600;
		}
		
		&::after,
		&:hover::after,
		&:active::after,
		&:focus::after {
			background: $gray-200;
		}
	}
}


//

.btn-icon {
	align-items: center;
	justify-content: center;
	border: .1875rem solid transparent;
	display: flex;	
	cursor: pointer;	
	height: 3.75rem;
	padding: 0;
	position: relative;
	width: 3.75rem;
	
	&:not(.btn){
		background: none;
	}
		
	.svg-icon {
		.sh & {
			stroke: $white;
		}
		.sh &,
		.sn & {
			stroke-width: 3px;
			
			@include media-breakpoint-up(xxl){
				width: 2rem;
				height: 2rem;
			}
		}
		//.--inverted & {
		//	stroke: $black;
		//}
	}
	
	&:hover,
	&:active,
	&:focus {
		color: $white;
		
		.svg-icon {
			.sh & {
				stroke: $primary;
			}
			//.--inverted & {
			//	stroke: $black;
			//	opacity: .5;
			//}
		}
	}
	
}